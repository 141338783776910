<!--我的报价-->
<template>
    <offer-detail v-if="offer" :offer="offer" />
</template>
<script>

import OfferDetail from './OfferDetail.vue';

export default {
    name: 'OfferMy',
    components: {
        OfferDetail: OfferDetail,
    },
    mounted(){
        this.getOffer();
    },
    data(){
        return {
            offer: null,
        };
    },
    methods: {
        getOffer(){
            let user  = JSON.parse(localStorage.getItem("user"));
            this.$axios.get(this.$kit.api.offer.detail+"?id="+user.id+"&t=6")
                .then((result) => {
                    console.log(result);
                    if(result.status){
                        result.data.transits.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.deliveries.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.pkgs.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.proxies.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                            e.proxyMin = e.proxyMin!=0?(e.proxyMin/100.0).toFixed(2):0;
                            e.proxyMax = e.proxyMax!=0?(e.proxyMax/100.0).toFixed(2):0;
                        });
                        result.data.pasteBill.price = (result.data.pasteBill.price/100.0).toFixed(2);
                    }
                    result.data.remark1 = null;
                    result.data.remark2 = null;
                    this.offer = result.data;
                });

        }
        

    }
}

</script>